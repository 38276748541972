//  Files Import
import * as ROUTES from "./constants/routes";
import "./App.css";
// Dependencies Import
// --------------------------------------
// --------------------------------------
import React, { lazy } from "react";
import { HashRouter, Route, Routes, } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

function App() {
  const Home = lazy(() => import("./screens/Home"));
  const Team = lazy(() => import("./screens/team/Team"));
  const Suppliers = lazy(() => import("./screens/suppliers/Suppliers"));
  const Contact = lazy(() => import("./screens/contact/Contact"));
  const ShopHome = lazy(() => import('./screens/shop/Home'))
  // const PAGENOTFOUND = lazy(() =>
  //   import("./screens/PageNotFound/PageNotFound")
  // );
  // const Cart = lazy(() => import("./screens/Cart"));
  // const Checkout = lazy(() => import("./screens/Checkout"));
  // const Category = lazy(() => import("./screens/CategoriesPage"));
  const loading = (
    <div className="loading_container">
      <div className="sk-spinner sk-spinner-pulse loading_spinner">
        <CircularProgress size={35} />
      </div>
    </div>
  );

  return (
    <>
      <HashRouter>
        {/* <React.Suspense fallback={loading}> */}
        {/* <Route path="*" element={<PAGENOTFOUND />} /> */}
        <Routes>
          <Route path={ROUTES.HOME}
            element={
              <React.Suspense fallback={loading}>
                <Home />
              </React.Suspense>
            }
          />
          <Route path={ROUTES.TEAM}
            element={
              <React.Suspense fallback={loading}>
                <Team />
              </React.Suspense>
            }
          />
          <Route path={ROUTES.SUPPLIERS}
            element={
              <React.Suspense fallback={loading}>
                <Suppliers />
              </React.Suspense>
            }
          />
          <Route path={ROUTES.CONTACT}
            element={
              <React.Suspense fallback={loading}>
                <Contact />
              </React.Suspense>
            }
          />
          <Route path={ROUTES.SHOP}
            element={
              <React.Suspense fallback={loading}>
                <ShopHome />
              </React.Suspense>
            }
          />
          {/* <Route path="/product/:productid" component={ProductInfo} />
        <Route path={ROUTES.WELCOME} component={Welcome} exact={true} /> 
        <Route path={ROUTES.CATEGORIEPAGE} component={Category} /> */}
        </Routes>
        {/* </React.Suspense>  */}
      </HashRouter>
    </>
  );
}

export default App;
